import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import Routes from "routes";
import { CookiesProvider } from "react-cookie";
import { NewsLetterProvider } from "context/NewsLetterContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

function App() {
  const queryClient = new QueryClient();
  return (
    <div className="app">
      <Router>
        <QueryClientProvider client={queryClient}>
          <CookiesProvider>
            <NewsLetterProvider>
              <Routes />
              <ReactQueryDevtools />
            </NewsLetterProvider>
          </CookiesProvider>
        </QueryClientProvider>
      </Router>
    </div>
  );
}

export default App;
