import { TOGGLE_DARK_MODE } from "../types";

const userPrefersDark = localStorage.darkMode
  ? localStorage.darkMode === "true"
  : window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;

const INITIAL_STATE = {
  darkMode: userPrefersDark
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_DARK_MODE:
      return {
        ...state,
        darkMode: !state.darkMode
      };
    default:
      return state;
  }
};
