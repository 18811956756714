import Icon from "components/Icon";
import React from "react";
import "./styles.css";

const WrappedIcon = ({
  id,
  className,
  width,
  height,
  style,
  iconProps,
  ...props
}) => {
  return (
    <div
      className={`wicon ${className || ""}`}
      style={{ width, height, ...style }}
      {...props}
    >
      <Icon id={id} {...iconProps} />
    </div>
  );
};

export default WrappedIcon;
