import React from "react";
import bounce from "assets/lotties/bouncinglogo.json";
// import { useLottie } from "lottie-react";
import Lottie from "react-lottie";
import "./style.css";

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: bounce
};

export const PageLoader = () => {
  // const { View } = useLottie(lottieOptions);
  return (
    <div className="page-overlay">
      <Lottie options={lottieOptions} style={{ width: "5rem" }} />
    </div>
  );
};
