import React from "react";
import { Route, Redirect } from "react-router-dom";

const PublicRoute = ({ component: Component, ...rest }) => {
  return true ? (
    <Route component={Component} {...rest} />
  ) : (
    <Redirect to="/home" />
  );
};

export default PublicRoute;
