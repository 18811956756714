import { NewsLetterModal } from "components/NewsletterModal";
import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useMemo
} from "react";
import { useCookies } from "react-cookie";

export const NewsLetterContext = createContext();

export const NewsLetterProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(() => setShowModal(!showModal), [showModal]);
  const [cookies] = useCookies(["nl"]);
  const providerValue = useMemo(
    () => ({
      showModal,
      setShowModal,
      toggleModal
    }),
    [toggleModal, showModal, setShowModal]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!cookies.acceeptedNL) {
        toggleModal();
      }
    }, 20000);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NewsLetterContext.Provider value={providerValue}>
      {children} <NewsLetterModal isOpen={showModal} closeModal={toggleModal} />
    </NewsLetterContext.Provider>
  );
};
