import React from "react";
import "./styles.css";
import PropTypes from "prop-types";

const Button = ({ label, className, variant, ...props }) => {
  return (
    <button
      aria-label={label}
      className={`btn ${className} ${variant ? `btn--co-${variant}` : ""}`}
      {...props}
    >
      {label}
    </button>
  );
};

Button.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    "cornflower-blue",
    "cosmos",
    "dark-purple",
    "lightpurple",
    "yellow"
  ])
};

Button.defaultProps = {
  className: "",
  variant: "cornflower-blue",
  hidelabel: "false"
};

export default Button;
