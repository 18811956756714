import { applyMiddleware, createStore } from "redux";
import reduxThunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import reducers from "./reducers";

let store;

if (
  window.location.origin.includes("localhost") ||
  window.location.origin.includes("staging")
) {
  store = createStore(
    reducers,
    composeWithDevTools(applyMiddleware(reduxThunk))
  );
} else {
  store = createStore(reducers, applyMiddleware(reduxThunk));
}

export default store;
