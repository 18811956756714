import ReactGA from "react-ga4";

// Event Categories
const CTA_BUTTON = "CTA_BUTTON";
const FORM_SUBMISSION = "FORM_SUBMISSION";
const PLANS = "PLANS";
const SOCIAL_MEDIA = "SOCIAL_MEDIA";

export const gaInitialize = () => {
  if (
    window.location.href.includes("localhost") ||
    window.location.href.includes("staging.da3cqevh2zxny.amplifyapp")
  ) {
    ReactGA.initialize("G-576EZ0SY5L", {
      debug: true
    });
  } else ReactGA.initialize("G-9F9X9CPBHQ");
};

export const gaRecordPageView = (location) => {
  ReactGA.send({
    hitType: "pageview",
    page: location.pathname + location.search,
    title: "Page view"
  });
};

export const gaRecordModalView = (label) => {
  ReactGA.event({
    category: "Open Modal",
    action: "Opened Doctor Profile",
    label
  });
};

export const gaEventJoinIddera = (label) => {
  ReactGA.event({
    category: CTA_BUTTON,
    action: "Join Iddera",
    label
  });
};

export const gaDowloadIddera = (label) => {
  ReactGA.event({
    category: CTA_BUTTON,
    action: "Dowload Iddera from Navbar",
    label
  });
};

export const gaEventSignin = (label) => {
  ReactGA.event({
    category: CTA_BUTTON,
    action: "Signin",
    label
  });
};

export const gaEventJoinWaitlist = () => {
  ReactGA.event({
    category: FORM_SUBMISSION,
    action: "Join Waitlist"
  });
};

export const gaEventSignup = (label) => {
  ReactGA.event({
    category: FORM_SUBMISSION,
    action: "Sign up",
    label
  });
};

export const gaEventNewsletter = (label) => {
  ReactGA.event({
    category: FORM_SUBMISSION,
    action: "Newsletter",
    label
  });
};

export const gaEventOpenAppStore = (label) => {
  ReactGA.event({
    category: CTA_BUTTON,
    action: "Download app",
    label
  });
};

export const gaFreePlan = (label) => {
  ReactGA.event({
    category: PLANS,
    action: "Free Plan Clicked",
    label
  });
};

export const gaMonthlyPlan = (label) => {
  ReactGA.event({
    category: PLANS,
    action: "Monthly Plan Clicked",
    label
  });
};

export const gaAnnualPlan = (label) => {
  ReactGA.event({
    category: PLANS,
    action: "Annual Plan Clicked",
    label
  });
};

export const gaGetUpdates = (label) => {
  ReactGA.event({
    category: FORM_SUBMISSION,
    action: "Clicked get update button",
    label
  });
};

export const gaModalForm = (label) => {
  ReactGA.event({
    category: FORM_SUBMISSION,
    action: "Subscribed for health updates",
    label
  });
};

export const gaClosedNewsletterModal = (label) => {
  ReactGA.event({
    category: FORM_SUBMISSION,
    action: "Closed newsletter modal",
    label
  });
};

export const gaIdderaFree = (label) => {
  ReactGA.event({
    category: CTA_BUTTON,
    action: "Get Iddera Free Button",
    label
  });
};

export const gaNookFree = (label) => {
  ReactGA.event({
    category: CTA_BUTTON,
    action: "Nook Free Button",
    label
  });
};

export const gaFacebook = (label) => {
  ReactGA.event({
    category: SOCIAL_MEDIA,
    action: "Opened Facebook",
    label
  });
};

export const gaTwitter = (label) => {
  ReactGA.event({
    category: SOCIAL_MEDIA,
    action: "Opened Twitter",
    label
  });
};

export const gaInstagram = (label) => {
  ReactGA.event({
    category: SOCIAL_MEDIA,
    action: "Opened Instagram",
    label
  });
};

export const gaTestimonialSlide = (label) => {
  ReactGA.event({
    category: "TESTIMONIAL",
    action: "Slide testimonial",
    label
  });
};

export const gaViewTestimonial = (label) => {
  ReactGA.event({
    category: "TESTIMONIAL",
    action: "View testimonial",
    label
  });
};
